import {Controller} from "@hotwired/stimulus";

export default class EstimatedMonthlyPaymentController extends Controller {

    connect() {
        super.connect();

        const { price } = this.element.dataset;

        if (!price) {
            console.warn('Unable to display estimated monthly payment. Price is not set');
            return;
        }
        if (!stripe) {
            console.warn('Unable to display estimated monthly payment. Stripe is not available.');
            return;
        }

        const elements = stripe.elements({
            appearance: {
                variables: {
                    fontSizeBase: '11px',
                    fontLineHeight: '13px'
                }
            }
        });
        const options = {
            amount: parseInt(price),
            currency: 'USD',
            countryCode: 'US',
        };
        const PaymentMessageElement = elements.create('paymentMethodMessaging', options);
        PaymentMessageElement.mount('#estimated-monthly-payment-label');

    }

}